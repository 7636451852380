import React from 'react';
import { Layout } from "layouts/layout";
import { graphql, HeadFC, HeadProps, PageProps } from "gatsby";
import { Container, Grid } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { markdownOptions } from "components/options/markdown-options";
import { SectionHeader } from "components/layout/section-header";
import { Page } from "models/schemas/page";
import { PageMetadata } from "components/layout/page-metadata";

export type PolicyProps = {
    page: Page;
}

const Policy: React.FC<PageProps<PolicyProps>> = ({ data }) => {

    const { page } = data;
    
    const date = new Date(page.updatedAt);
    
    return (
        <Layout>
            <Container maxWidth={'md'}>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <SectionHeader 
                            title={page.link.label}
                            description={`Last Updated on ${date.toDateString()} at ${date.toLocaleTimeString("gb")}`}
                            gutterTop
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {page.contents.map(content => (
                        <Markdown options={markdownOptions}>
                            {content.richText.markdown}  
                        </Markdown>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}
export default Policy;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.page.metadata}/>
)

export const query = graphql`
    query($id: String!) {
        page: graphCmsPage(id: { eq: $id }) {
            link {
                label
                url
            }
            contents {
                richText {
                    markdown
                }
            }
            updatedAt
            metadata {
                title
                description
                thumbnail {
                  url
                }
                embedImage {
                  url
                }
                jsonLD
            }
        }
    }`